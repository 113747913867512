import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import Video from "../../components/video"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Connecticut Firm Overview - Bartlett & Grippe",
    description:
      "If you need a dedicated voice for your personal injury, wrongful death, or abuse case, contact Bartlett & Grippe, LLC. Our experienced lawyers will help you achieve the best possible outcome. ",
    heroH1: "Connecticut Firm Overview",
    heroImage: "photo-bg-staff.jpg",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">About the firm</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/s3CgYqqZRhI?rel=0" />
            </div>
            <div>


              <p className="mb-8">
                The attorneys at Bartlett & Grippe, LLC are nationally recognized
                in the field of Personal Injury law and Wrongful Death cases.
                Whether you lost a loved one due to the negligence of another,
                have suffered a significant injury in a car accident, or were
                sexually abused by a so-called “trusted” authority figure, you
                need dedicated lawyers who will be your voice in the legal system.
                We will aggressively pursue the best possible outcome for you,
                both emotionally and financially. We are your firm. We have
                successfully handled wrongful death and serious injury cases from
                across the country. Don’t settle for less, and don’t feel you may
                not have a case without talking to us. Consultations are free and
                you are under no obligation.
              </p>
              <p className="mb-8">
                Bartlett & Grippe, LLC formed in 2022 with the merger of two
                successful litigation firms: Bartlett Legal Group, LLC and
                Ouellette, Deganis, Gallagher & Grippe. Frank Bartlett and Joe
                Grippe both began their legal careers at Ouellette, Deganis &
                Gallagher, LLC, a well-known firm founded in Cheshire in 1996.
                Attorney Grippe and Attorney Bartlett both gained valuable trial
                experience during this time representing both Plaintiffs and
                Defendants in personal injuries matters.
              </p>
              <div className="mb-8 ">
                <StaticImage
                  src="../../images/bartlett-grippe-firm-96415-US-Original-E31.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Award from Best Lawyers for 2025"
                  width={300}
                />
              </div>
              <div className="w-full md:w-1/2 mb-8 float-right">
                <Video videoSrcURL="https://www.youtube.com/embed/Ck4dcR07h5c" />
              </div>
              <p className="mb-8">
                In 2012, Attorney Bartlett left Ouellette, Deganis & Gallagher,
                LLC and started his own firm where he devoted his practice to
                representing injured parties. Over the past ten years, Attorney
                Bartlett grew his practice into a nationally recognized firm
                representing injured parties and their families in catastrophic
                injury and wrongful death cases, as well as representing adult
                survivors of sexual abuse.
              </p>
              <p className="mb-8">
                In 2014, Attorney Grippe was named a partner at Ouellette, Deganis
                & Gallagher, LLC and soon thereafter the firm name was changed to
                Ouellette, Deganis, Gallagher & Grippe, LLC. For the past 8 years,
                Attorney Grippe along with his partners expanded upon the firm’s
                already great reputation in the legal community. The firm
                continued to handle defense matters for some of the country’s
                largest insurance companies while also growing its personal injury
                practice.
              </p>
              <p className="mb-8">
                In 2021, Attorney Bartlett and Attorney Grippe began discussions
                about merging the two firms and on July 1, 2022, Bartlett and
                Grippe was formed. Bartlett and Grippe will continue to practice
                in the following areas: catastrophic personal injury, wrongful
                death, medical malpractice, nursing home abuse, product liability
                and insurance defense litigation. Clients can expect the same
                focus, attention to detail and high-quality service that they have
                come to expect from Ouellette, Deganis, Gallagher & Grippe, LLC
                and Bartlett Legal Group, LLC, to continue moving forward.
              </p>
              <Link to="/firm-overview/our-team/"><button className="rounded hover:opacity-90 text-xl p-8 xl:text-3xl mt-4 xl:mt-11 f-f-r py-6 bg-green-400 text-white font-bold">Meet Our Team</button></Link>


            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
